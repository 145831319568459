import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Importing carousel styles
// import icon from "../../assets/Website_content/TR_Image_desktop.png";
import page1 from "../../assets/MarketplaceHomeCropped.webp";
import page2 from "../../assets/DiwaliOffer.webp";
import page3 from "../../assets/gypsyCarousel.webp";

/**
 * CTACarousel component displays a carousel with 3 CTA slides.
 * Each slide has a background image, CTA text, and a button that links to relevant pages.
 */
const CTACarousel = ({selectedCity}) => {
    const [, setIsMobile] = useState();

    // Update isMobile on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 640);
        };

        // Add resize event listener
        window.addEventListener("resize", handleResize);

        // Cleanup the event listener
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="w-full mx-auto">
            {/* Carousel settings */}
            <Carousel
                autoPlay              // Enables automatic slide transition
                infiniteLoop          // Carousel loops infinitely
                showThumbs={false}    // Hides thumbnail images below the carousel
                showStatus={false}    // Hides the current slide status
                interval={5000}       // Interval between auto slides (in ms)
                transitionTime={1000} // Time to transition between slides (in ms)
            >
                {/* Slide 1 - Find the Best Gym */}
                <div className="relative">
                    <img
                        src={page1} // Replace with the actual background image for this slide
                        alt="Find the Best Gym"
                        className="object-cover w-full h-96 lg:h-96"
                    />
                    <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-75 pb-2">
                        <h1 className="text-4xl md:text-6xl lg:text-8xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-complementPurple to-secondary mt-14 md:mt-0">
                            Train Hard, <br className='flex lg:hidden'/> Save More!
                        </h1>

                        <p className="text-sm md:text-xl lg:text-2xl text-white mt-6 md:mt-6 lg:mt-4">Unlock Exclusive Gym Deals with Train Rex!</p>

                        <button
                            onClick={() => window.location.href = `/${selectedCity}/gyms`}
                            className="scale-75 md:scale-90 lg:scale-100 mt-14 md:mt-6 px-16 lg:px-20 py-2 bg-secondary text-xl text-primary font-bold rounded-3xl"
                        >
                            Explore
                        </button>
                    </div>
                </div>

                {/* Slide 2 - Free Consultation Offer */}
                <div className="relative">
                    <img
                        src={page2}
                        alt="Free Fitness & Diet Consultation"
                        className="object-cover w-full h-96"
                    />
                    <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-75 pb-2">
                        <h1 className="text-4xl md:text-6xl lg:text-8xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-secondary to-red mt-10 md:mt-0">
                            Free Diet & Fitness Consultation!
                        </h1>
                        
                        <p className="text-sm md:text-base lg:text-lg text-white mt-4 mx-8 md:mx-14">
                            Get a Free 1 Hour Consultation on buying a gym membership (1 month or more) as part of our<br className='flex lg:hidden'/>Diwali Offer!
                        </p>
                        
                        <button
                            className="scale-75 md:scale-90 lg:scale-100 mt-6 px-16 lg:px-20 py-2 bg-secondary text-xl text-primary font-bold rounded-3xl"
                            onClick={() => window.location.href = `/${selectedCity}/gyms`}
                        >
                            Explore
                        </button>
                    </div>
                </div>

                {/* Slide 3 - Featured Gym: Gypsy */}
                <div className="relative">
                    <img
                        src={page3}
                        alt="Gypsy Gym"
                        className="object-cover w-full h-96"
                    />
                    <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-75">
                        <h1 className="text-4xl md:text-5xl lg:text-8xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-red to-complementPurple pb-2 mt-12 md:mt-0">
                            Try Train Rex <br className='flex lg:hidden' /> Verified Gyms
                        </h1>
                        <p className="text-sm lg:text-lg text-white mt-4 mx-8">Experience a seamless entry at our verified partner at exclusive prices.</p>
                        <button
                            onClick={() => window.location.href = '/delhi/gyms/gypsy'}
                            className="scale-75 md:scale-75 lg:scale-100 mt-6 px-16 lg:px-20 py-2 bg-secondary text-xl text-primary font-bold rounded-3xl"
                        >
                            Buy Membership
                        </button>
                    </div>
                </div>
            </Carousel>
        </div>
    );
};

export default CTACarousel;
