import { collection, getDocs, query, where, limit } from "@firebase/firestore";
import { db } from "../config/firebase";
import { refineData } from "./basicHelper";
import { formatWordToLowerCase } from "./basicHelper";


export const getCurrencyToDisplay = (myLocation = 'India') => {
    myLocation = formatWordToLowerCase(myLocation);
    switch (myLocation) {
        case 'unitedkingdom' :
            return '£'
        
        default :
            return '₹'
    }
}

export const getCurrency = (myLocation = 'India') => {
    myLocation = formatWordToLowerCase(myLocation);
    switch (myLocation) {
        case 'unitedkingdom' :
            return 'GBP'
        
        default :
            return 'INR'
    }
}

/**
 * Gets the price of the membership
 * Depends on the number of months the user wants to buy the membership
 * @param   :   gymName, 
 *              city, 
 *              months - The no of months user wants to buy a membership for
 * @returns :   price of the membership * 100 (as razorpay accepts currency in paise/cents)
 */
export const getAmount = async (_myData) => {
    const myData = refineData(_myData)
    // const myData = _myData
    try {
        const gymRef = collection(db, 'Cities', myData.city, 'Gyms');
        const gymQuery = query(gymRef, where('codeName', '==', myData.gymName), limit(1));
        const gymDataSnap = await getDocs(gymQuery);

        if (!gymDataSnap.empty) {
            let gymData;
            gymDataSnap.forEach(doc => {
                gymData = doc.data(); // Assuming only one document matches the query
            });

            switch(myData.months) {
                case 1:
                    return gymData.monthlyPrice * 100;

                case 3:
                    return gymData.quarterlyPrice * 100;

                case 6:
                    return gymData.halfYearlyPrice * 100;

                case 12:
                    return gymData.yearlyPrice * 100;

                default:
                    return 0;
            }
        } else {
            // console.error("No such document!");
            return null;
        }
    } catch (error) {
        // console.error("Error getting document:", error);
        return null;
    }
};

