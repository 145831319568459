import { calculateDistance } from "../MarketplaceHelpers/MapHelpers/DistanceAndLocationHelpers";
import { sortBySmartCriteria } from "./GymScoringAlgorithm";

/**
 * Main function to find the best gyms for the user based on their preferences.
 *
 * @param   {Object} formDeets        - Details from the user's form input.
 * @param   {Array}  gymList          - List of all gyms in the city.
 * @param   {Object} userLocation     - The user's current location with latitude and longitude.
 * @returns {Array}                   - Sorted list of gyms based on user preferences.
 */
export const findGymAlgorithm = async ( formDeets, userLocation , gymList) => {
    // Temporarily filter zerodope
    const filteredByName = gymList.filter((gym) => !gym.gymName.toLowerCase().includes("zerodope"));

    // Step 1: Filter gyms based on Women's Only preference
    let filteredGyms = filterByWomensOnly(filteredByName, formDeets.womensOnly);

    // Step 2: Filter gyms based on budget
    if (filteredGyms.length > 1)
        filteredGyms = filterByBudget(filteredGyms, formDeets.budget);

    // Step 3: Filter gyms based on timing
    // Have been filtered out for now
    // filteredGyms = filterByTiming(filteredGyms, formDeets.timing);

    // Step 4: Sort gyms by distance
    const sortedGyms = await sortBySmartCriteria(filteredGyms, formDeets, userLocation);

    // Ensure at least one gym is returned
    return sortedGyms.length > 0 ? sortedGyms : getClosestGym(gymList, userLocation);
};

/**
 * Filters gyms based on the Women's Only preference.
 * If `womensOnly` is true, returns only women's gyms; otherwise, all gyms.
 *
 * @param   {Array} gymList           - List of all gyms.
 * @param   {Boolean} womensOnly      - Whether Women's Only filter is enabled.
 * @returns {Array}                   - Filtered list of gyms.
 */
const filterByWomensOnly = (gymList, womensOnly) => {
    if (!womensOnly) return gymList;
    return gymList.filter((gym) => gym.isWomensOnly);
};

/**
 * Filters gyms based on the user's budget.
 * If no gyms match, expand the budget incrementally by 10%.
 *
 * @param   {Array} gymList           - List of gyms to filter.
 * @param   {Array} budget            - Budget range [min, max].
 * @returns {Array}                   - Filtered list of gyms.
 */
const filterByBudget = (gymList, budget) => {
    // console.log('filtering by budget');
    let filteredGyms = gymList.filter(
        (gym) =>
            gym.prices.single.monthlyPrice >= budget[0] &&
            gym.prices.single.monthlyPrice <= budget[1]
    );

    while (filteredGyms.length === 0) {
        // Expand budget by 10%
        budget[0] = Math.max(0, budget[0] - budget[0] * 0.1); // Decrease minimum by 10%
        budget[1] = budget[1] + budget[1] * 0.1; // Increase maximum by 10%
        // console.log(`budget : ${budget[0]} : ${budget[1]}`);

        // if budget is a lot then break the loop
        if (budget[1] > 50000) break;
        filteredGyms = gymList.filter(
            (gym) =>
                gym.prices.single.monthlyPrice >= budget[0] &&
                gym.prices.single.monthlyPrice <= budget[1]
        );
    }
    return filteredGyms;
};

// --------------------------------------------------------------------------------
// ------------------------------- TIME SECTION -----------------------------------
// --------------------------------------------------------------------------------
/**
 * Filters gyms based on whether they are open at the user's preferred time.
 *
 * @param   {Array} gymList           - List of gyms to filter.
 * @param   {Number} timing           - Preferred gym time in minutes since midnight.
 * @returns {Array}                   - Filtered list of gyms.
 */
// const filterByTiming = (gymList, timing) => {
//     return gymList.filter((gym) => {
//         const openingTime = convertTimeToMinutes(gym.openingTime);
//         const closingTime = convertTimeToMinutes(gym.closingTime);
//         return timing >= openingTime && timing <= closingTime;
//     });
// };

/**
 * Converts a time string (e.g., "09:00") to minutes since midnight.
 *
 * @param   {String} time             - Time in "HH:MM" format.
 * @returns {Number}                  - Time in minutes since midnight.
 */
// const convertTimeToMinutes = (time) => {
//     const [hours, minutes] = time.split(":").map(Number);
//     return hours * 60 + minutes;
// };

// --------------------------------------------------------------------------------
// ------------------------------- TIME SECTION -----------------------------------
// --------------------------------------------------------------------------------

// /**
//  * Sorts gyms based on their distance from the user's location.
//  *
//  * @param   {Array} gymList           - List of gyms to sort.
//  * @param   {Object} userLocation     - The user's current location with latitude and longitude.
//  * @returns {Array}                   - List of gyms sorted by distance.
//  */
// const sortByDistance = (gymList, userLocation) => {
//     return gymList
//         .map((gym) => ({
//             ...gym,
//             distance: calculateDistance(userLocation, gym.latitude, gym.longitude),
//         }))
//         .sort((a, b) => a.distance - b.distance);
// };

/**
 * Gets the closest gym when all filters fail to return results.
 *
 * @param   {Array} gymList           - List of all gyms.
 * @param   {Object} userLocation     - The user's current location with latitude and longitude.
 * @returns {Array}                   - A list with the single closest gym.
 */
const getClosestGym = (gymList, userLocation) => {
    return [
        gymList.reduce((closest, gym) => {
            const distance = calculateDistance(userLocation, gym.latitude, gym.longitude);
            return !closest || distance < closest.distance
                ? { ...gym, distance }
                : closest;
        }, null),
    ];
};
