import { doc, updateDoc } from "@firebase/firestore";
import { mapBoxKey } from "../../../config/SecretKeys";
import { db } from "../../../config/firebase";
import { cityList, gymList } from "../../../components/Databases";
  
/**
 * Retrieves latitude and longitude from a given address using the Mapbox Geocoding API.
 *
 * @param {string} address - The address to be geocoded.
 * @returns {Promise<{lat: number, lng: number} | null>} 
 * An object containing latitude and longitude if successful, or `null` if an error occurs.
 *
 * @example
 * const coordinates = await getCoordinatesFromAddress("1600 Pennsylvania Ave NW, Washington, DC 20500");
 * console.log(coordinates); // { lat: 38.8977, lng: -77.0365 }
 *
 * @throws {Error} If no coordinates are found for the given address or if the API call fails.
 */
export async function getCoordinatesFromAddress(address) {

    // Mapbox API token for authentication
    const mapboxToken = mapBoxKey; // Ensure this variable holds your Mapbox API key

    // Encode the address to make it URL-friendly
    const encodedAddress = encodeURIComponent(address);

    // Construct the API request URL
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedAddress}.json?access_token=${mapboxToken}`;

    try {
        // Fetch the geocoding data from Mapbox
        const response = await fetch(url);

        // Parse the response data as JSON
        const data = await response.json();

        // Validate and extract location data
        if (data.features && data.features.length > 0) {

            const [lng, lat] = data.features[0].center; // Coordinates are in [lng, lat] format
            return { lat, lng }; // Return the coordinates

        } else {
            // console.warn("No coordinates found for the provided address."); // Handle empty responses
            return null;
        }
    } catch (error) {
        // console.error("Error fetching coordinates:", error);
        return null; // Return null if an error occurs
    }
}

/**
 * Updates the latitude and longitude fields of a gym document in Firestore.
 *
 * @param {Object} gymInfo - Information about the gym.
 * @param {string} gymInfo.city - City where the gym is located.
 * @param {string} gymInfo.gymName - Name of the gym.
 * @param {Object} coordinates - Coordinates to update in the Firestore document.
 * @param {number} coordinates.lat - Latitude of the gym.
 * @param {number} coordinates.lng - Longitude of the gym.
 *
 * @returns {Promise<void>} Resolves when the update is complete.
 *
 * @example
 * const gymInfo = { city: "Delhi", gymName: "FitnessCenter" };
 * const coordinates = { lat: 28.6139, lng: 77.2090 };
 * await storeCoordinatesInDB(gymInfo, coordinates);
 */
const storeCoordinatesInDB = async (gymInfo, coordinates) => {
    try {
        // Reference to the gym document
        const gymRef = doc(db, cityList, gymInfo.city, gymList, gymInfo.gymName);

        // Update the latitude and longitude fields
        await updateDoc(gymRef, {
            latitude: coordinates.lat,
            longitude: coordinates.lng,
        });

    } catch (error) {
        console.error(
            `Error storing coordinates in DB for gym: ${gymInfo.gymName}`,
            error
        );
    }
};

/**
 * Fetches coordinates for a given address and stores them in the Firestore database.
 *
 * @param {Object} gymInfo - Information about the gym to update in the database.
 * @param {string} gymInfo.city - City where the gym is located.
 * @param {string} gymInfo.gymName - Name of the gym.
 * @param {string} address - Address of the gym for geocoding.
 *
 * @returns {Promise<{lat: number, lng: number} | null>} 
 * Resolves to the coordinates `{ lat, lng }` if successful, or `null` if the geocoding process fails.
 */
export const getCoordinatesAndStoreThemInDB = async (gymInfo, address) => {
    // First get the coordinates for storing
    let coordinates =   await getCoordinatesFromAddress(address);

    // Now once we have them, store them in the database
    if (coordinates) await storeCoordinatesInDB(gymInfo, coordinates);

    return coordinates;
}



// UNUSED BLOCK OF CODE!
// /**
//  * Provides latitude and longitude of all the gyms of the city
//  * @param {Object} gymData  -   all the gyms information
//  * @returns {Object}        -   gyms latitude and longitude with their names
//  */
// export const getMapLocations = async (gymData) => {
//     let mapLocations = [];
//     console.log('Here!');
//     try {
// 		for (const gym of gymData) {
//             let latAndLng = {};
// 			if (gym.latitude !== undefined && gym.longitude !== undefined) {
//                 latAndLng = {
//                     lat             :   parseFloat(gym.latitude),
//                     lng             :   parseFloat(gym.longitude),
//                 }    
// 			}
//             else {
//                 latAndLng   =   await getCoordinatesFromAddress(
//                     `${gym.address}, ${gym.cityDisplayName}, ${gym.pincode || ''}`
//                 )
//             }
//             mapLocations.push({
//                 lat             :   latAndLng.lat,
//                 lng             :   latAndLng.lng,
//                 name            :   gym.gymDisplayName,
//                 locality        :   gym.locality,
//                 rating          :   gym.rating,
//                 address         :   gym.address,
//                 address2        :   `${gym.cityDisplayName}, ${gym.pincode || ''}`,
//                 gymName         :   gym.gymName,
//                 city            :   gym.city,
//                 image           :   gym.image,
//                 mrp             :   gym.price,
//                 exclusivePrice  :   getSellingPrice(gym.exclusivePrice), // Add Commission Here
//             });
//             // else {
//             //     mapLocations.push({
//             //         lat             :   55.944600683675404,
//             //         lng             :   -3.1892459852322173, // Corrected "lgn" to "lng"
//             //         name            :   'easteregg',
//             //         gymDisplayName  :   'The University Of Edinburgh',
//             //         locality        :   'The Founders Met Here',
//             //         rating          :   5,
//             //         address         :   'Congratulations on finding the Easter Egg!',
//             //         gymName         :   'easteregg',
//             //         city            :   'Edinburgh',
//             //         image           :   [''],
//             //         mrp             :   "0",
//             //         exclusivePrice  :   "0",
//             //     });
//             // }
// 		}
//     } catch (error) {
//       	// console.log("Error fetching map locations: ", error);
//     }
//     console.log(mapLocations);
//     return mapLocations;
// };
  


// // Convert gymArray coordinates (latitude, longitude) to numbers
// export const convertGymCoordinates = (gyms) => {
//     let mapLocations = [];
//     for (const gym of gyms) {
//         if (gym.latitude === '' || gym.longitude === '') {
//             mapLocations.push({
//                 lat             :   55.944600683675404,
//                 lng             :   -3.1892459852322173, // Corrected "lgn" to "lng"
//                 name            :   'easteregg',
//                 gymDisplayName  :   'The University Of Edinburgh',
//                 locality        :   'The Founders Met Here',
//                 rating          :   5,
//                 address         :   'Congratulations on finding the Easter Egg!',
//                 gymName         :   'easteregg',
//                 city            :   'Edinburgh',
//                 image           :   [''],
//                 mrp             :   "0",
//                 exclusivePrice  :   "0",
//             });
//         } else {
//             mapLocations.push({
//                 ...gym,
//                 lat: parseFloat(gym.latitude),
//                 lng: parseFloat(gym.longitude), // Keep the correct "lng" here as well
//             });
//         }
//     }
//     return mapLocations; // Ensure the function returns the mapLocations array
// };