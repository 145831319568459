
// const theme = "dark"

// const DarkTheme = {

//         primary: '#f2f2f2',
//         background: '#333333',
//         card: '#444444',
//         text: '#f2f2f2',
//         border: '#f2f2f2',
//         notification: '#f2f2f2',

// };
// const LightTheme = {

//         primary: '#f2f2f2',
//         background: '#f2f2f2',
//         card: '#f2f2f2',
//         text: '#333333',
//         border: '#f2f2f2',
//         notification: '#f2f2f2',
//     }



export const themeColors =  {

    bg: '#262525', //Anjali Black
    fd:'rgba(255,255,255,0.18)', //White
    fg: '#9EBC80', //Middle Olive
    white: '#f2f2f2',
    text: '#f2f2f2',
    graywhite : '#f2f2f2',
    disabledText: '#6c6969',
    blue: '#3b5998',
    darkText: '#444444',
    lightAccent : '#EAF0D8', // Light Olive
    accent : '#465130', //Dark olive
    // accent : '#A872CA',

    primary: '#465130', //Dark olive
    secondary: '#9EBC80', //Middle Olive
    tertiary: '#F6FAEA', // Light Olive
    lightTertiary: '#F7F7F7',
    black: '#000000',
    white2: '#F7F7F7',
    red: '#E87B7B',
    gray: {
        100: '#f7fafc',
        200: '#edf2f7',
        300: '#e2e8f0',
        400: '#cbd5e0',
        500: '#a0aec0',
        600: '#718096',
        700: '#4a5568',
        800: '#2d3748',
        900: '#1a202c',
    },
    logoGray: '#2f2d2d',
    highlightRed: '#FF3131',
    darkTertiary : '#F0F4DC',
    primaryComplement : '#512D90',
    complementPurple:'#9D80BC',
}





export const colors = {
    heading: 'text-gray-700',
    button: '#50C878'
}

export const categoryBG = {
    food: '#E1D3EE',
    commute: '#B0E3D3',
    shopping: '#EcFAD7',
    entertainment: '#ffdfdd',
    other: '#CAD309'
}

export const graphColors = [
    '#9EBC80', // secondary
    '#FFFFFF', // white
    '#465130', // primary
    '#d0d4c4', // light gray
    '#262525', // dark gray
    '#708454', // dark green
]