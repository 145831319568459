import React, { useState } from "react";
import { Range, getTrackBackground } from "react-range";
import { CheckBoxComponent } from "../../components/HeadlessUI";
import { themeColors } from "../../theme";
import { findGymAlgorithm } from "./AlgorithmsToFindBestGym";
import BestGymModal from "./BestGymModal";

/**
 * Main Component of the file
 * Opens a modal which takes the information from the user and,
 * Displays The perfect gym for them
 * 
 * @param   {List}      gymList                 :   List of all the gyms in the city
 * @param   {Object}    userLocation            :   The latitude and longitude of the user's current location 
 * @param   {Function}  onClose                 :   Function to close the modal
 * 
 * @returns {Object}                            :   The Best Gym For the User
 */
const FindYourGymForm = ({ gymList, userLocation, onClose }) => {
    const [budget, setBudget] = useState([0, 10000]);
    const [womensOnly, setWomensOnly] = useState(false);
    const [secondStage, setSecondStage] = useState(false);
    const [bestGyms, setBestGyms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStep, setLoadingStep] = useState(0);

    const loadingMessages = [
        "Calculating Distances...",
        "Analyzing Gym Data...",
        "Applying machine learning algorithms...",
        "Fetching The Top Gyms..."
    ];

    const timeToDisplayEachMessage  = 1500
    const NumberOfLoadingMessages   = loadingMessages.length;
    const timeDelay                 = NumberOfLoadingMessages * timeToDisplayEachMessage;

    /**
     * Handles form submission.
     * Filters gyms based on the user's preferences and calculates the best gym.
     * Moves to the second stage (BestGymModal) after showing loading steps.
     */
    const handleSubmit = async () => {
        setIsLoading(true); // Start loading
        let currentStep = 0;

        // Simulate loading steps
        const interval = setInterval(() => {
            if (currentStep < loadingMessages.length - 1) {
                setLoadingStep((prevStep) => prevStep + 1);
                currentStep++;
            } else {
                clearInterval(interval);
            }
        }, timeToDisplayEachMessage);

        // Wait for 5 seconds while showing loading steps
        setTimeout(async () => {
            const formDeets = {
                budget: [budget[0], budget[1]],
                womensOnly: womensOnly,
                bestDistance: Infinity,
            };

            const gyms = await findGymAlgorithm(formDeets, userLocation, gymList);
            setBestGyms(gyms); // Set gyms for BestGymModal
            setSecondStage(true); // Move to the second stage
            setIsLoading(false); // Stop loading
        }, timeDelay);
    };

    return (
        <>
            {!secondStage ? (
                // First stage: Form Modal
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
                    <div className="bg-secondary p-8 rounded-2xl shadow-2xl border-4 border-primary w-full max-w-3xl relative">
                        {/* Close Button */}
                        <button
                            className="absolute top-4 right-4 text-primary hover:text-tertiary text-3xl font-bold"
                            onClick={onClose}
                        >
                            &times;
                        </button>
        
                        {!isLoading ? (
                            <>
                                <h2 className="text-3xl font-bold text-tertiary mb-6 text-center">
                                    Let's Find The Perfect Gym For You
                                </h2>
        
                                {/* Budget Slider */}
                                <div className="mb-6">
                                    <label className="block font-medium text-lg text-primary mb-2">
                                        Budget Per Month (₹)
                                    </label>
                                    <div className="flex justify-between text-md text-primary mb-2">
                                        <span>Min : 
                                            <span className="font-extrabold">
                                                ₹{budget[0]}
                                            </span>
                                        </span>
                                        <span>Max : 
                                            <span className="font-extrabold">
                                                ₹{budget[1]}
                                            </span>
                                        </span>
                                    </div>
                                    <Range
                                        step={100}
                                        min={0}
                                        max={10000}
                                        values={budget}
                                        onChange={setBudget}
                                        renderTrack={({ props, children }) => (
                                            <div
                                                {...props}
                                                className="h-2 w-full rounded-md"
                                                style={{
                                                    background: getTrackBackground({
                                                        values: budget,
                                                        colors: ["#ccc", themeColors.complementPurple, "#ccc"],
                                                        min: 0,
                                                        max: 10000,
                                                    }),
                                                }}
                                            >
                                                {children}
                                            </div>
                                        )}
                                        renderThumb={({ props }) => (
                                            <div
                                                {...props}
                                                className="w-6 h-6 rounded-full bg-primary shadow-lg flex items-center justify-center"
                                            >
                                            </div>
                                        )}
                                    />
                                </div>
        
                                {/* Women's Only Checkbox */}
                                <div className="mt-6 mb-6">
                                    <label className="inline-flex items-center">
                                        <CheckBoxComponent
                                            enabled={womensOnly}
                                            setEnabled={setWomensOnly}
                                        />
                                        <span className="ml-3 text-primary text-lg">Women's Only</span>
                                    </label>
                                </div>
        
                                {/* Actions */}
                                <div className="mt-8 flex justify-center gap-6">
                                    <button
                                        className="bg-tertiary text-logoGray px-6 py-3 rounded-lg shadow-md hover:bg-primary hover:text-tertiary font-semibold"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="bg-tertiary text-logoGray px-6 py-3 rounded-lg shadow-md hover:bg-primary hover:text-tertiary font-semibold"
                                        onClick={handleSubmit}
                                    >
                                        Find Gym
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="flex flex-col items-center justify-center h-96">
                                <h2 className="text-2xl font-bold text-primary mb-6">
                                    {loadingMessages[loadingStep]}
                                </h2>
                                <div className="w-12 h-12 border-4 border-primary border-t-transparent rounded-full animate-spin"></div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                // Second stage: BestGymModal
                <BestGymModal
                    gyms={bestGyms}
                    onClose={onClose} // Close function for BestGymModal
                />
            )}
        </>
    );
};

export default FindYourGymForm;
