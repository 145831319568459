import { Checkbox } from '@headlessui/react'
// import { CheckIcon } from 'lucide-react'
import { CheckIcon } from '@heroicons/react/16/solid'

export const CheckBoxComponent = ({enabled, setEnabled}) => {
    return (
        <Checkbox
            checked={enabled}
            onChange={setEnabled}
            className={`group size-4 rounded-md bg-white/20 ring-1 ring-white/15 ring-inset data-[checked]:bg-white`}
        >
        <CheckIcon className={`hidden size-4 fill-black group-data-[checked]:block`} />
        
        </Checkbox>
    )
}
