import { toast } from 'react-hot-toast';

const Alert = (type, message) => {
    switch (type) {
        case 'success':
            toast.success(message);
            break;
        case 'error':
            toast.error(message);
            break;
        case 'info':
            toast(message);
            break;
        case 'warning':
            toast(message, { icon: '⚠️' });
            break;
        default:
            toast(message);
    }
};

export default Alert;
