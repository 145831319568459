import { getCurrencyToDisplay } from "../../PaymentHelpers";

// const percentIncreaseOnExclusivePrice = 0.1     // 10% increment

const getPercentageOnExclusivePrice = (exclusivePrice) => {
    const price = typeof exclusivePrice === 'number' ? exclusivePrice : parseInt(exclusivePrice, 10)
    // For prices less than 15000 rs
    if (price < 15000) 
        return 0.05
    // For higher prices, can change
    else
        return 0.05
}

/** 
 * Gets the selling prices for the gym
 * @param {Number} exclusivePrice   -   Exclusive Price of a duration
 * @returns {Number}                -   Price of the gym
 */
export const getSellingPrice = (exclusivePrice) => {
    return Math.round(exclusivePrice * (1 + getPercentageOnExclusivePrice(exclusivePrice)) - 1);
}

/**
 * Goes Through the List of prices, and
 * Returns the List of selling prices.
 * @param {Object} exclusivePrices - An object containing numerical values of exclusive prices.
 * @returns {Object} - A new object with updated selling prices.
 */
export const getListOfSellingPrices = (exclusivePrices) => {
    const sellingPrices = {};

    // Iterate over each price category and calculate the selling price (price * 1.1)
    for (const key in exclusivePrices) {

        if (typeof exclusivePrices[key] === 'number') {  

            const originalPrice = exclusivePrices[key];
            const sellingPrice = getSellingPrice(originalPrice);  // Calculate the selling price
            sellingPrices[key] = sellingPrice;  // Store the calculated selling price
        }
    }

    return sellingPrices;  // Return the new object with updated prices
};


/**
 * DisplayPrice component to show MRP (with strike-through) and selling price.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {number} props.mrp - The MRP price to be displayed with a strike-through.
 * @param {number} props.sellingPrice - The selling price to be displayed in bold.
 * @param {string} props.currency - The currency symbol to display before the prices.
 * @returns JSX Element displaying the MRP and selling price.
 */

// Previous code

export const DisplayPrice = ({ mrp, sellingPrice, country}) => {
    return (
        <span className="flex justify-around gap-2 items-center bg-none">
            {/* MRP with strike-through */}
            <span className="line-through text-red bg-transparent scale-90 whitespace-nowrap">
                {getCurrencyToDisplay(country)} {mrp - 1 || "..."}
            </span>
            {" "}
            {/* Selling price in bold */}
            <span className="font-bold py-1 md:py-2 rounded-full bg-secondary relative scale-110 hover:scale-125 transition-all whitespace-nowrap duration-500 w-24">
                {getCurrencyToDisplay(country)} {sellingPrice || "..."}
                <div className="absolute scale-[70%] md:scale-90 top-3 md:top-7 -right-1 md:right-0 h-6 w-6 rounded-full border-2 border-secondary bg-tertiary flex justify-center items-center">
                    <div className="font-normal md:font-semibold text-secondary text-4xl">+</div>
                </div>
            </span>
        </span>
    );
};

/**
 * Helper function to extract prices (exclusive and MRP) from gym data.
 * @param {object} gymData - The gym data containing pricing information.
 * @param {string} membershipType - Type of membership (e.g., daily, monthly).
 * @returns {object} - Object containing the exclusive prices and MRP for single, couple, and family memberships.
 */
export const extractPrices = (gymData, membershipType) => {
    const prices = gymData.prices;

    /**
     * Function to get the Price if it is not null
     * @param   {String} price 
     * @returns {String}
     */
    const getPriceValue = (price) => price && price !== "" ? price : null;

    return {
        single: {
            mrp             : getPriceValue(prices?.single[membershipType]),
            exclusivePrice  : getPriceValue(prices?.single[`exclusive${capitalize(membershipType)}`])
        },
        couple: getPriceValue(prices?.couple[membershipType]) ? {
            mrp             : getPriceValue(prices.couple[membershipType]),
            exclusivePrice  : getPriceValue(prices.couple[`exclusive${capitalize(membershipType)}`])
        } : null,
        family: getPriceValue(prices?.family[membershipType]) ? {
            mrp             : getPriceValue(prices.family[membershipType]),
            exclusivePrice  : getPriceValue(prices.family[`exclusive${capitalize(membershipType)}`])
        } : null
    };
};


/**
 * Capitalizes the first letter of the string.
 * @param {string} str - String to capitalize.
 * @returns {string} - Capitalized string.
 */
const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);