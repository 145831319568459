import emailjs from 'emailjs-com';
import { useState } from 'react';
import { PillButton } from './ButtonsLibrary';
import LoadingSpinner from '../../components/LoadingSpinner';

/**
 * CustomAlert Component
 * Displays an alert overlay with a message and a close button.
 *
 * @param {Object} props
 * @param {string} props.message - The alert message to display.
 * @param {Function} props.onClose - Function to close the alert.
 * @returns {JSX.Element} - The rendered alert component.
 */
const CustomAlert = ({ message, onClose }) => {
    return (
        <div className="fixed inset-0 bg-bg bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-secondary rounded-lg p-6 text-center shadow-lg">
                <p className="mb-4">{message}</p>
                <button onClick={onClose} className="bg-tertiary text-primary py-2 px-4 rounded-lg hover:bg-primary hover:text-tertiary transition-all duration-500">
                    Close
                </button>
            </div>
        </div>
    );
};

/**
 * Validates an email address using a regex pattern.
 * @param {string} email - Email to validate.
 * @returns {boolean} - True if email is valid, false otherwise.
 */
const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

/**
 * Validates a phone number to ensure it is exactly 10 digits.
 * @param {string} phone - Phone number to validate.
 * @returns {boolean} - True if phone number is valid, false otherwise.
 */
const validatePhone = (phone) => /^\d{10}$/.test(phone);

/**
 * Sends an email using EmailJS with provided parameters.
 *
 * @param {Object} params - Parameters for the email template.
 * @param {Function} setLoading - Function to toggle loading state.
 * @param {Function} setAlertMessage - Function to set alert message.
 * @param {Function} setShowAlert - Function to toggle alert visibility.
 */
const sendEmail = (params, setLoading, setAlertMessage, setShowAlert) => {
    const serviceId  = 'service_bifr90d'; // Add your EmailJS service ID
    const templateId = 'template_5iuoinr';
    const userId     = 'UghyKy_Cpzm5G5PLp'; // Your EmailJS user ID

    emailjs
        .send(serviceId, templateId, params, userId)
        .then((response) => {
            setLoading(false);
            setAlertMessage("Thank you for connecting with us!");
            setShowAlert(true);
        })
        .catch((error) => {
            setLoading(false);
            setAlertMessage("Failed to send the message. Please try again.");
            setShowAlert(true);
        });
};

/**
 * ContactUs Component
 * This component provides a contact form that allows users to send messages to the company.
 *
 * @param {Object} props
 * @param {Object} props.gymData - Data about the gym (optional).
 * @param {Function} props.onClose - Function to close the contact form (optional).
 * @returns {JSX.Element} - The rendered contact form component.
 */
const ContactUs = ({ gymData, onClose }) => {
    const [formData, setFormData]           = useState({
        name: '', email: '', phone: '', city: '', message: '', category: ''
    });
    const [loading, setLoading]             = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [alertMessage, setAlertMessage]   = useState('');
    const [showAlert, setShowAlert]         = useState(false);

    /**
     * Updates the form data state based on user input.
     * @param {Event} e - The input change event.
     */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    /**
     * Updates the selected category in the form data.
     * @param {string} value - The selected category value.
     */
    const handleCategoryClick = (value) => {
        setSelectedCategory(value);
        setFormData({ ...formData, category: value });
    };

    /**
     * Handles the form submission, validates inputs, and sends an email if validation passes.
     * @param {Event} e - The form submission event.
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const { name, email, phone, city, message, category } = formData;

        if (!validateEmail(email)) {
            setAlertMessage("Please enter a valid email address.");
            setShowAlert(true);
            setLoading(false);
            return;
        }

        if (!validatePhone(phone)) {
            setAlertMessage("Please enter a valid 10-digit phone number.");
            setShowAlert(true);
            setLoading(false);
            return;
        }

        if (!category) {
            setAlertMessage("Please select a category.");
            setShowAlert(true);
            setLoading(false);
            return;
        }

        if (!name || !email || !phone || !city || !message) {
            setAlertMessage("Please fill out all the fields.");
            setShowAlert(true);
            setLoading(false);
            return;
        }

        const templateParams = {
            from_name   : name,
            to_name     : 'Train Rex',
            message     : message,
            phone       : phone,
            city        : city,
            category    : category,
            email       : email,
        };

        sendEmail(templateParams, setLoading, setAlertMessage, setShowAlert);
    };

    /**
     * Closes the alert popup.
     */
    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return (
        <div className="bg-tertiary flex items-center justify-center w-full px-4 py-16">
            <div className="flex flex-col bg-tertiary w-fit lg:w-1/2 rounded-lg">
                <div>
                    <h2 className="flex text-2xl lg:text-4xl justify-start md:justify-center font-semibold mb-6 text-primary">Connect with Us!</h2>
                    <div className="mb-4 flex flex-wrap gap-x-2 lg:gap-x-1 justify-between">
                        <PillButton label="Gyms" value="Gyms" onClick={() => handleCategoryClick('Gyms')} selected={selectedCategory === 'Gyms'} />
                        <PillButton label="Classes" value="Classes" onClick={() => handleCategoryClick('Classes')} selected={selectedCategory === 'Classes'} />
                        <PillButton label="User" value="User" onClick={() => handleCategoryClick('User')} selected={selectedCategory === 'User'} />
                        <PillButton label="Collaborator" value="Collaborator" onClick={() => handleCategoryClick('Collaborator')} selected={selectedCategory === 'Collaborator'} />
                    </div>
                    <div className="flex flex-row gap-x-4 font-normal text-xs md:text-base">
                        <input type="text" id="name" name="name" placeholder="✈️ Full Name" required value={formData.name} onChange={handleChange} className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-4 w-1/2 border-2 md:border-4 lg:rounded-2xl border-secondary placeholder-color custom-input" />
                        <input type="text" id="email" name="email" placeholder="✉️ Email Address" required value={formData.email} onChange={handleChange} className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-4 w-1/2 border-2 md:border-4 lg:rounded-2xl border-secondary placeholder-color custom-input" />
                    </div>
                    <div className="flex flex-row gap-x-4 font-normal text-xs md:text-base">
                        <input type="text" id="phone" name="phone" placeholder="☎️ Phone Number" required value={formData.phone} onChange={handleChange} className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-4 w-1/2 border-2 md:border-4 lg:rounded-2xl border-secondary placeholder-color custom-input" />
                        <input type="text" id="city" name="city" placeholder="🗺️ City" required value={formData.city} onChange={handleChange} className="h-12 px-4 rounded-xl bg-tertiary text-primary mb-4 w-1/2 border-2 md:border-4 lg:rounded-2xl border-secondary placeholder-color custom-input" />
                    </div>
                    <div className="flex flex-row gap-x-4 font-normal text-xs md:text-base">
                        <textarea id="message" name="message" placeholder="Write a message" required value={formData.message} onChange={handleChange} className="h-20 lg:h-40 px-4 pt-2 rounded-xl bg-tertiary text-primary mb-4 w-full border-2 md:border-4 lg:rounded-2xl border-secondary placeholder-color custom-input"></textarea>
                    </div>
                    <button onClick={handleSubmit} className={`${loading ? 'bg-primary' : 'bg-secondary'} text-tertiary py-2 px-4 rounded-2xl hover:bg-primary transition-all duration-200`}>
                        {loading ? 
                            (<LoadingSpinner />) : 'Submit'
                        }
                    </button>
                </div>
                {showAlert && <CustomAlert message={alertMessage} onClose={handleCloseAlert} />}
            </div>
        </div>
    );
};

export default ContactUs;
