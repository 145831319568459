import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertToDirectLink } from "../MarketplaceHelpers/ButtonsLibrary";
import TR_Logo from '../../assets/Icon-512.webp';
import { getSellingPrice } from "../MarketplaceHelpers/gymDataHelpers/PriceHelpers";

/**
 * Modal to display the best gyms with navigation and "Buy Membership" functionality.
 *
 * @param   {Array} gyms             - List of gyms to display (max 3 gyms).
 * @param   {Function} onClose       - Function to close the modal.
 * @returns {JSX.Element}            - Gym details modal.
 */
const BestGymModal = ({ gyms, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(0); // Track the current gym index
    const navigate = useNavigate();

    // Limit the number of gyms to display to 3
    const limitedGyms = gyms.slice(0, 3);
    const currentGym = limitedGyms[currentIndex];

    /**
     * Navigate to the next gym.
     */
    const handleNext = () => {
        if (currentIndex < limitedGyms.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    /**
     * Navigate to the previous gym.
     */
    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    /**
     * Handle the "Buy Membership" button click.
     * Navigate to the designated page and pass gym details.
     */
    const handleBuyMembership = () => {
        navigate(`/${currentGym.city}/gyms/${currentGym.gymName}`, { state: { gymDetails: currentGym } });
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70 p-4">
            <div className="relative bg-secondary p-6 rounded-2xl shadow-2xl w-full max-w-5xl">
                {/* Close Button */}
                <button
                    className="absolute top-4 right-4 text-primary text-3xl hover:text-tertiary"
                    onClick={onClose}
                >
                    &times;
                </button>

                {/* Gym Name */}
                <h2 className="text-2xl lg:text-4xl font-bold text-primary text-center mb-6">
                    {currentGym.gymDisplayName}
                </h2>

                <div className="relative flex flex-col items-center">
                    {/* Rectangle Image */}
                    <div className="w-full max-w-[800px] h-[300px] lg:h-[500px]">
                        <img
                            src={convertToDirectLink(currentGym.image[0]) || TR_Logo}
                            alt={`${currentGym.gymDisplayName}`}
                            className="w-full h-full object-cover rounded-xl shadow-lg"
                        />
                    </div>

                    {/* Left Navigation Button */}
                    {currentIndex > 0 && (
                        <button
                            onClick={handlePrev}
                            className="absolute left-4 lg:left-6 top-1/2 transform -translate-y-1/2 text-primary sm:text-tertiary text-4xl lg:text-4xl hover:text-tertiary"
                        >
                            &#8249;
                        </button>
                    )}

                    {/* Right Navigation Button */}
                    {currentIndex < limitedGyms.length - 1 && (
                        <button
                            onClick={handleNext}
                            className="absolute right-4 lg:right-6 top-1/2 transform -translate-y-1/2 text-primary sm:text-tertiary text-4xl lg:text-4xl hover:text-tertiary"
                        >
                            &#8250;
                        </button>
                    )}
                </div>

                {/* Distance and Price */}
                <div className="text-center mt-6">
                    <div className="text-xl lg:text-2xl font-bold text-primary mb-2">
                        Distance: {currentGym.distance} km
                    </div>
                    <div className="text-xl lg:text-2xl font-bold text-primary">
                        Price: ₹{currentGym.isExclusive === false ? currentGym.prices.single.exclusiveMonthlyPrice : getSellingPrice(currentGym.prices.single?.exclusiveMonthlyPrice) || "Not Available"}/month
                    </div>
                </div>

                {/* Gym Count */}
                <p className="text-sm lg:text-lg text-tertiary mt-4 text-center">
                    We found {limitedGyms.length} gyms that are perfect for you!
                </p>

                {/* Buy Membership Button */}
                <div className="flex justify-center mt-8">
                    <button
                        className="w-full lg:w-2/3 px-4 py-3 lg:px-6 lg:py-3 bg-tertiary text-primary rounded-lg shadow-md hover:bg-primary hover:text-tertiary font-bold text-lg lg:text-xl"
                        onClick={handleBuyMembership}
                    >
                        Buy Membership
                    </button>
                </div>
            </div>
        </div>
    );
};

export default BestGymModal;
