import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../config/firebase"; // Adjust the import according to your Firebase setup

const useAuthStatus = () => {
    const [isUserPresent, setIsUserPresent] = useState(false);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Subscribe to the user's auth state changes
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setIsUserPresent(true);
                setUser(currentUser);

                // Fetch and send the ID token to the WebView
                currentUser.getIdToken().then((idToken) => {
                    window.ReactNativeWebView.postMessage(
                        JSON.stringify({ token: idToken })
                    );
                }).catch((error) => {
                    console.error("Error fetching ID token:", error);
                });
            } else {
                setIsUserPresent(false);
                setUser(null);
            }
            setLoading(false);
        });

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, []);

    return { isUserPresent, user, loading };
};

export default useAuthStatus;
