import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Next } from "../../assets/WelcomeScreenImage";
import { Carousel, Placard } from "./ButtonsLibrary";
import { fetchAllBlogs, fetchAllGyms, getPlacardProps } from "./MarketplaceHelper";

/**
 * NearYou component: Renders a section displaying gyms or blogs near the user.
 * 
 * @param {Object}  props                   - The props object containing the necessary data.
 * @param {string}  props.bg                - The background color for the container.
 * @param {Array}   props.gymArray          - Array of gym objects passed as initial data.
 * @param {string}  props.heading           - The heading text to display.
 * @param {string}  props.headingColor      - The color of the heading text.
 * @param {string}  props.headingMobile     - The heading text for mobile devices.
 * @param {string}  props.locationIconColor - The color for the location icon.
 * @param {string}  props.selectedCity      - The city name selected by the user.
 * @param {string}  props.size              - The size class for the container (e.g., margin and width).
 * 
 * @returns {JSX.Element} The rendered NearYou component.
 */
export const NearYou = (props) => {
    // State for handling responsiveness and data
    const [ isMobile,    setIsMobile     ]  =   useState(window.innerWidth <= 640);
    const [ cityGymList, setCityGymList  ]  =   useState(props.gymArray);
    const [ blogList,    setBlogList     ]  =   useState(null);
    const [ loading,     setLoading      ]  =   useState(true);
    const navigate                          =   useNavigate();

    // Handle screen resizing for responsiveness
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 640);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Fetch gym or blog data based on the heading
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (props.heading.includes('BLOGS')) {
                const blogsList = await fetchAllBlogs();
                setBlogList(blogsList);
            } else {
                if (cityGymList.length === 0) {  // Fix: Check for an empty array instead of `== []`
                    const allGyms = await fetchAllGyms(props.selectedCity);
                    setCityGymList(allGyms);
                }
            }
            setLoading(false);
        };
        fetchData();
    }, [props.selectedCity, props.heading, cityGymList.length]);

    // Handle navigation logic
    const handleNavigation = () => {
        const propToPass = props.selectedCity;
        if (props.heading.includes('BLOGS')) {
            navigate('/blogs');
        } else if (props.heading.includes('FITNESS CENTRES')) {
            navigate(`/${propToPass}/gyms`, { state: cityGymList });
        } else if (props.heading.includes('CLASSES')) {
            navigate(`/${propToPass}/classes`, { state: { propToPass } });
        }
    };

    // Scroll to the top when the gymName changes
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [props.gymName]);

    /**
     * Render placards for gyms or blogs
     * 
     * @param {Array} list - List of gyms or blogs to render.
     * @param {boolean} isBlog - Flag to determine if the list contains blogs.
     * 
     * @returns {JSX.Element[]} An array of rendered placard components.
     */
    const renderPlacards = (list, isBlog) => {
        return (list || []).slice(0, 3).map((item, index) => (
            <Placard
                key={index}
                {...getPlacardProps(item, props)}
            />
        ));
    };

    // If the component is loading data
    if (loading) {
        return (
            <div>
                <div className="loader mb-6 mt-14 text-6xl md:text-8xl font-bold">
                    Train Rex
                </div>
            </div>
        );
    }

    return (
        <div className={`${props.bg} ${props.size} flex flex-col rounded-l-3xl lg:rounded-3xl lg:mt-4 mb-8 lg:mb-16 h-[400px] lg:h-1/4`}>
            <button 
                onClick={handleNavigation} 
                className="py-8 flex flex-row justify-between items-center px-8 transition-all duration-300 ease-in-out"
            >
                <h1
                    className={`hidden lg:flex text-lg lg:text-4xl font-bold ${props.headingColor} 
                    transform transition-transform duration-300 ease-in-out hover:scale-105 text-left`}
                >
                    {isMobile ? props.headingMobile : props.heading}
                </h1>
                <div className="flex flex-row transform transition-transform duration-300 ease-in-out hover:scale-125">
                    <h3 className="text-lg lg:text-2xl text-secondary font-bold ">Explore All</h3>
                    <Next className="w-6 lg:w-12 h-4 lg:h-7 -mr-6 lg:-mr-0 mt-1 lg:mt-0" />
                </div>
            </button>

            <div className="w-full">
                {!props.heading.includes('BLOG') && (
                    <>
                        <div className="hidden lg:flex flex-row justify-between">
                            {renderPlacards(cityGymList, false)}
                        </div>
                        <div className="lg:hidden">
                            <Carousel slides={renderPlacards(cityGymList, false)} />
                        </div>
                    </>
                )}

                {props.heading.includes('BLOG') && (
                    <>
                        <div className="hidden lg:flex flex-row justify-between">
                            {renderPlacards(blogList, true)}
                        </div>
                        <div className="lg:hidden">
                            <Carousel slides={renderPlacards(blogList, true)} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
