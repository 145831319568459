import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import Modal from "react-modal";
import { useState } from "react";
import { auth } from "../../config/firebase";
import icon from '../../assets/CTA_01.webp';
import LoadingSpinner from "../../components/LoadingSpinner";
import { Eye, EyeOff } from "lucide-react";
import { sendPasswordResetEmail } from "firebase/auth"; 
import Alert from "../../components/customComponents/Alert";
import toast from "react-hot-toast";

const SignInPopup = ({ onClose, myData, switchModal }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signingIn, setSigningIn] = useState(false);
    const [areCredentailsWrong, setAreCredentailsWrong] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isForgotPassword, setIsForgotPassword] = useState(false); 
    const [emailSent, setEmailSent] = useState(false);

    const handleForgotPassword = async () => {
        if (!email) {
            alert("Please enter your email address to reset the password.");
            return;
        }
        try {
            await sendPasswordResetEmail(auth, email);
            setEmailSent(true);
        } catch (error) {
            alert("Failed to send reset email. Please try again.");
            console.error(error);
        }
    };

    // Function to send token to React Native app
    const sendTokenToApp = (token) => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ token }));
        }
    };

    // Monitor authentication state changes
    onAuthStateChanged(auth, (user) => {
        if (user) {
            user.getIdToken().then((token) => {
                sendTokenToApp(token); // Send the token to React Native
            });
        }
    });

    return (
        <Modal
            isOpen={true}
            onRequestClose={onClose}
            contentLabel="Sign In"
            className="fixed inset-0 flex items-center justify-center z-50 text-black"
            overlayClassName="fixed inset-0 bg-logoGray bg-opacity-60 z-50"
        >
            <div className="bg-secondary rounded-3xl shadow-lg max-w-[90%] md:max-w-[85%] lg:max-w-[52%] max-h-[660px] md:max-h-[400px] lg:max-h-[600px] relative flex flex-col md:flex-row p-6 md:p-10 lg:p-14">
                {/* Left segment */}
                <div className="w-full md:w-1/2 flex items-center justify-center p-0 md:p-4 lg:p-8 rounded-l-3xl overflow-hidden">
                    <img
                        src={icon}
                        alt="Logo"
                        className="w-full h-auto object-contain"
                    />
                </div>

                {/* Right segment */}
                <div className="w-full md:w-1/2 flex flex-col items-center justify-center space-y-4 p-6">
                    {!isForgotPassword ? (
                        <>
                            <div className="text-lg md:text-xl lg:text-2xl text-primary font-semibold text-center">
                                Sign In
                            </div>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter email"
                                className={`text-xs lg:text-base py-2 px-2 md:px-3 lg:px-4 md:py-2 border rounded-3xl w-full transition-all duration-300 focus:scale-105 outline-none ${areCredentailsWrong ? 'border-highlightRed' : 'border-none'}`}
                            />
                            <div className="relative w-full">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Enter password"
                                    className={`text-xs lg:text-base py-2 px-2 md:px-3 lg:px-4 md:py-2 border rounded-3xl w-full transition-all duration-300 focus:scale-105 outline-none ${areCredentailsWrong ? 'border-highlightRed' : 'border-none'}`}
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowPassword((prev) => !prev)}
                                    className="absolute top-1/2 right-3 transform -translate-y-1/2 text-black hover:text-primary focus:outline-none"
                                >
                                    {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                                </button>
                            </div>
                            <button
                                onClick={() => handleSignIn(email, password, onClose, myData, setSigningIn, setAreCredentailsWrong)}
                                className="bg-tertiary text-sm lg:text-base text-black py-2 md:px-4 md:py-2 lg:px-6 lg:py-3 rounded-full hover:bg-primary hover:text-tertiary transition-all duration-300  hover:scale-105 w-full"
                            >
                                Sign In
                            </button>
                            <div className="text-center text-tertiary font-semibold">OR</div>
                            <div
                                onClick={() => setIsForgotPassword(true)}
                                className="text-sm text-tertiary cursor-pointer transition-all duration-300 hover:font-semibold"
                            >
                                Forgot Password?
                            </div>
                            <div
                                onClick={switchModal}
                                className="text-sm text-tertiary cursor-pointer transition-all duration-300 hover:font-semibold "
                            >
                                Not A Member Yet? <span className="underline hover:text-primary">Sign Up</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="text-lg md:text-xl lg:text-2xl text-primary font-semibold text-center">
                                Forgot Password
                            </div>
                            <p className="text-sm text-center text-white">
                                Enter your email address below, and we'll send you a link to reset your password.
                            </p>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Enter email"
                                className="text-xs lg:text-base py-2 px-2 md:px-3 lg:px-4 md:py-2 border rounded-3xl w-full transition-all duration-300 focus:scale-105 outline-none"
                            />
                            <button
                                onClick={handleForgotPassword}
                                className="bg-tertiary text-sm lg:text-base text-black py-2 md:px-4 md:py-2 lg:px-6 lg:py-3 rounded-full hover:bg-primary hover:text-tertiary transition-all duration-300  hover:scale-105 w-full"
                            >
                                Send Reset Link
                            </button>
                            {emailSent && (
                                <div className="text-center text-green-500 font-semibold">
                                    A password reset email has been sent!
                                </div>
                            )}
                            <button
                                onClick={() => setIsForgotPassword(false)}
                                className="text-sm text-tertiary mt-4 cursor-pointer transition-all duration-300 hover:font-semibold"
                            >
                                Back to Sign In
                            </button>
                        </>
                    )}
                </div>

                {/* Close button */}
                <button
                    onClick={onClose}
                    className="absolute top-2 md:top-4 right-4 md:right-6 text-tertiary hover:text-black transition text-4xl"
                >
                    &times;
                </button>

                {signingIn && (
                    <div className='z-50 fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
                        <div className='h-44 w-44'>
                            <LoadingSpinner />
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default SignInPopup;



/**
 * Handles Sign In after user enters the credentials
 * @param {string} email
 * @param {string} password
 * @param {function} onClose
 * @param {dict} myData
 */
const handleSignIn = async (email, password, onClose, myData, setSigningIn, setAreCredentailsWrong) => {

    try {
        setSigningIn(true);
        const response = await signUserIn(email, password);

        if (response.status) {
            toast.success("Sign In Successfull")
        }

        if (response.status === false) {
            setSigningIn(false);
            setAreCredentailsWrong(true);
            Alert('error', 'Error signing in');
            return;
        }
        setSigningIn(false);
        setAreCredentailsWrong(false);
        onClose();
    } catch (error) {
        // Handle error
    }
}

const signUserIn = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        return {
            status: true,
            user: user
        };

    } catch (error) {
        return {
            status: false,
            user: error
        }
    }
}
