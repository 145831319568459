import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";  // Import hooks
// import { useParams, useNavigate, useLocation, Link } from "react-router-dom";  // Import hooks
import logoSVG from "../../assets/Website_content/TR_Website_content-02.svg";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/firebase";
import SearchBar from "../MarketplaceHelpers/SearchBar";
import useAuthStatus from "../../hooks/clientAuth";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { auth } from "../../config/firebase";
import { UserLoginModal } from "../ModalHelpers/UserCreds";
import {getUserData} from "../userDatabaseHelper";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";

function NavBar_Marketplace({ selectedCity, onLocationChange }) {

    const { isUserPresent, user} = useAuthStatus();
    const [menuOpen       , setMenuOpen       ] = useState(false);
    const [userCity       ,                   ] = useState(selectedCity? selectedCity : 'NCR');  // Initialize with citySlug or default
    const [trexConins     , setTrexCoins      ] = useState(0.00);
    const [showSignIn     , setShowSignIn     ] = useState(false);
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const profileMenuRef = useRef(null);
    const link           = useLocation();


    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleProfileMenu = () => {
        setProfileMenuOpen(!profileMenuOpen);
    };

    const logMenuItemClick = (itemName) => {
        logEvent(analytics, `menuClick, ${itemName}`);
    };

    const handleLogout = async () => {
        await auth.signOut();
        toast.success("Logged out successfully");
        setProfileMenuOpen(false);
    };

    // Handle click outside of the profile menu to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                profileMenuRef.current &&
                !profileMenuRef.current.contains(event.target)
            ) {
                setProfileMenuOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Checks if the user is a guest and if the user is not logged in
        const isGuest = localStorage.getItem("usertype") === "guest";

        // If the user is a guest and the user is not logged in, show the sign in modal
        if (isGuest && !user) {
            // Show the sign in modal after 15 seconds
            const firstTimeout = setTimeout(() => {
                setShowSignIn(true);
            }, 60000);

            // Show the sign in modal after 40 seconds
            const interval = setInterval(() => {
                setShowSignIn(true);
            }, 40000);

            return () => {
                // Clear the timeout and interval when the component unmounts
                clearTimeout(firstTimeout);
                clearInterval(interval);
            };
        }
        // Function to fetch the trex of the user
        const fetchTrexCoins = async () => {
            const userData = await getUserData(user?.uid);
            setTrexCoins(userData?.trexCredits ? userData?.trexCredits : 0);}
        fetchTrexCoins()
    }, [user]); // Only run this effect when the user changes

    return (
        <div className="bg-tertiary fixed top-0 left-0 right-0 z-50 shadow-xl">
            <div className="bg-tertiary py-3 md:py-4 pl-16 pr-6 flex justify-between items-center gap-8 md:gap-x-4">
                <div className="block lg:hidden" style={{ marginLeft: -40 }}>
                    <button
                        onClick={toggleMenu}
                        className="text-primary focus:outline-none"
                    >
                        <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3 6h18v2H3V6zm0 5h18v2H3v-2zm0 5h18v2H3v-2z"
                            />
                        </svg>
                    </button>
                </div>
                

                <a href="/" className="w-2/3 md:w-1/3 flex items-center mb-2 lg:mb-0 ">
                    <img src={logoSVG} alt="Logo" className="h-8" />
                </a>

                {!link.pathname.includes('blogs') &&
                    <div className="hidden md:inline-flex w-full lg:w-2/3"> {/* Added this div on top of search bar to control the css */}
                        <SearchBar
                            location={userCity}
                            onLocationChange={onLocationChange}
                            className="hidden lg:inline-flex"
                        />
                    </div>}

                {isUserPresent ? (
                    <div
                        className="relative flex items-center gap-4 cursor-pointer"
                        ref={profileMenuRef}
                    >
                         {/* Display the user's profile picture and Trex */}
                        <div className="flex items-center gap-2">
                            <AccountCircleIcon fontSize="large" onClick={toggleProfileMenu} style={{ color: 'var(--secondary)' }} className="cursor-pointer" />
                            <div>
                                <div onClick={toggleProfileMenu} className="flex gap-1 text-lg text-primary font-bold cursor-pointer items-center">
                                    {/* Display first name only on small screens and full name on medium+ screens */}
                                    <span className="block md:hidden">{user?.displayName?.split(" ")[0] || "User"}</span>
                                    <span className="hidden md:block">{user?.displayName?.replace(" ", "\u00A0") || "User"}</span>
                                </div>
                                <div className="text-xs font-bold text-gray-500 flex gap-[1px]">
                                        <span className="whitespace-nowrap">Trex : </span>
                                        <span className="text-secondary"> {trexConins} </span>
                                </div>
                            </div>
                        </div>
                        {profileMenuOpen && (
                            <div className="absolute right-[-50px] top-8 mt-2 w-48 border-secondary bg-white border-[1px] rounded-md hover:rounded-md shadow-lg z-50">

                                <Link to={'/profile'}
                                    className="block hover:bg-secondary px-4 py-2 text-left hover:text-white rounded-md hover:rounded-md text-gray-700 w-full"
                                >
                                    Profile
                                </Link>

                                <button
                                    onClick={handleLogout}
                                    className="block  hover:bg-red px-4 py-2 text-left hover:text-white rounded-md hover:rounded-md text-gray-700 w-full"
                                >
                                    Logout
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <div>
                        <button
                            onClick={() => setShowSignIn(!showSignIn)}
                            className="bg-primary text-tertiary hover:bg-secondary py-1 lg:py-2 px-3 lg:px-4 rounded-full md:mr-0 mb-2"
                        >
                            Sign<span className="ml-1">In/Up</span>
                        </button>
                    </div>
                )}
            </div>


            <UserLoginModal
                modalIsOpen={showSignIn}
                setModalIsOpen={setShowSignIn}
                myData={{ city: userCity }}
            />


            {!link.pathname.includes('blogs') &&
                <div className="flex md:hidden pb-4 justify-center">
                    <SearchBar
                        location={userCity}
                        onLocationChange={onLocationChange}
                        className="flex md:hidden"
                    />
                </div>}

            <nav
                className={`w-1/3 h-full lg:flex py-0 px-0 lg:w-auto font-bold ${menuOpen
                    ? "w-auto flex fixed left-0 right-auto bottom-auto bg-tertiary z-50 rounded-b-2xl border-x-4 border-b-4 border-secondary"
                    : "hidden"
                    }`}
            >
                <ul className="md:flex flex-col lg:flex-row text-sm gap-4 bg-secondary w-full lg:justify-center justify-start">
                    <li>
                        <a
                            // href="/delhi/gyms"
                            href={`/${userCity}/gyms`}
                            className="block py-2 px-4 rounded-md text-left lg:text-center text-lg text-tertiary"
                            onClick={() => logMenuItemClick("Gyms/Classes")}
                        >
                            Gyms/Classes
                        </a>
                    </li>
                    <li>
                        <a
                            href="/AppDescription"
                            className="block py-2 px-4 rounded-md text-left lg:text-center text-lg text-tertiary"
                            onClick={() => logMenuItemClick("Train Rex App")}
                        >
                            Train Rex App
                        </a>
                    </li>
                    <li>
                        <a
                            href="/blogs"
                            className="block py-2 px-4 rounded-md text-left lg:text-center text-lg text-tertiary"
                            onClick={() => logMenuItemClick("Our Blog")}
                        >
                            Our Blogs
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default NavBar_Marketplace;
