import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import JSConfetti from "js-confetti";
import { generatePdf } from "../../helperFunctions/MarketplaceHelpers/PaymentHelpers/BillGeneration";
import AeMoorkh from "../../assets/AeMoorkhSuccess.webp";

/**
 * PaymentSuccessPage Component
 * Displays a confirmation page after a successful payment, including confetti animation and options to print the receipt.
 * 
 * @returns {JSX.Element} - Renders the confirmation page.
 */
const PaymentSuccessPage = () => {
    const confettiRef = useRef(null); // Reference for confetti instance
    const location = useLocation(); // Access the state passed via navigate
    const navigate = useNavigate(); // For navigation
    const billInfo = location.state?.billInfo; // Extract billInfo from state

    // Redirect to home if billInfo is not provided
    useEffect(() => {
        if (billInfo[0].mrp === undefined) {
            alert('No Bill Information!');
            navigate("/");
        }
    }, [billInfo, navigate]);

    /**
     * useEffect Hook
     * Triggers the confetti animation when the page loads by creating a new instance of JSConfetti.
     */
    useEffect(() => {
        const jsConfetti = new JSConfetti();
        confettiRef.current = jsConfetti;

        // Add confetti with fun emojis and parameters
        jsConfetti.addConfetti({
            emojis: ["🎉", "💪", "🔥", "🏋️‍♂️"],
            confettiRadius: 6,
            confettiNumber: 100,
        });
    }, []);

    if (!billInfo) return null; // Avoid rendering if billInfo is missing

    return (
        <div className="min-h-screen bg-secondary flex flex-col items-center justify-center">
            {/* Confetti-triggered area */}
            <div className="container max-w-6xl p-8 bg-white rounded-lg shadow-lg">
                {/* Top Section: Image and Title */}
                <div className="flex flex-col md:flex-row">
                    {/* Image Section */}
                    <div className="w-full md:w-1/2 flex items-center justify-center mb-4 md:mb-0">
                        <img
                            src={AeMoorkh}
                            alt="Success"
                            className="rounded-lg w-full h-auto"
                        />
                    </div>

                    {/* Text Section */}
                    <div className="w-full md:w-1/2 flex flex-col justify-center md:pl-8">
                        {/* Title */}
                        <div className="text-3xl md:text-5xl font-bold text-center mb-4">
                            🎉 Payment Successful! 🎉
                        </div>

                        {/* Subtitle */}
                        <div className="text-lg md:text-xl font-semibold text-center mb-4">
                            "Thank you for trusting us. Let’s hit the gym and smash your goals!" 💪
                        </div>
                    </div>
                </div>

                {/* Middle Section: Billing Information */}
                <div className="mt-6 text-center">
                    {/* Display MRP */}
                    <div className="text-lg mb-2">
                        MRP: <span className="line-through">₹{billInfo[0].mrp}</span>
                    </div>

                    {/* Display Amount Paid */}
                    <div className="text-2xl font-bold mb-2">
                        Amount Paid: ₹{billInfo[0].amount / 100}
                    </div>

                    {/* Display Savings */}
                    <div className="text-primary text-3xl font-semibold mb-4">
                        Total Savings: ₹{(billInfo[0].mrp - billInfo[0].amount / 100).toFixed(2)}
                    </div>

                    {/* Display Membership Expiration Dates */}
                    <div className="mt-4">
                        Your Gym Membership expires on:
                        <span className="block font-bold">{billInfo[0].endDate}</span>
                    </div>
                    {billInfo.length > 1 &&
                        billInfo.slice(1).map((bill, index) => (
                            <div key={index} className="mt-2">
                                {bill.user.displayName}'s Gym Membership expires on:
                                <span className="block font-bold">{bill.endDate}</span>
                            </div>
                        ))}
                </div>

                {/* Bottom Section: Buttons */}
                <div className="flex justify-center space-x-4 mt-6">
                    {/* Print Button */}
                    <button
                        onClick={() => generateAllBills(billInfo)}
                        className="px-8 py-3 bg-primary text-white rounded-lg font-semibold hover:bg-tertiary hover:text-primary"
                    >
                        Print Receipt
                    </button>

                    {/* Close Button */}
                    <button
                        onClick={() => navigate("/")} // Redirect to home
                        className="px-8 py-3 bg-primary text-white rounded-lg font-semibold hover:bg-tertiary hover:text-primary"
                    >
                        Go to Homepage
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccessPage;

/**
 * Generates all bills for the provided bill information.
 * @param {Array} billInfo - Array of billing information.
 */
const generateAllBills = async (billInfo) => {
    for (const bill of billInfo) {
        
        await generatePdf(bill, bill.response);
    }
};
