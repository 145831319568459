/**
 * GymsSorting.js
 * 
 * Sorting algorithm
 * Helps in Sorting the gyms for the Gym's List
 */

/**
 * Sorts the gym list in descending order based on the calculated value 
 * using the formula thisWeekViews**2 + totalViews.
 * If the values are equal, sorts by NoOfReview in descending order.
 * 
 * @param   {Array}  gymList     -   List of all the gyms that needs to be sorted.
 * @returns {Array}              -   Sorted List with gyms that generate the most value at the top.
 */
export const sortGyms = (gymList) => {
    //  For logs
    // const debugging = false;

    // Sort the gyms in descending order based on the calculated value
    const sortedList = gymList.sort((a, b) => {
        const valueA = calculateGymValue(a);
        const valueB = calculateGymValue(b);

        // First compare by calculated gym value
        if (valueB !== valueA) {
            return valueB - valueA;
        }

        // If values are equal, compare by NoOfReview in descending order
        return b.NoOfReview - a.NoOfReview;
    });

    // if (debugging) {
    //     // Log the sorted gyms in the format 'gymName : thisWeekViews : totalViews : calculatedValue : NoOfReview'
    //     sortedList.forEach(gym => {
    //         console.log(`${gym.gymName} : ${gym.thisWeekViews} : ${gym.totalViews} : ${calculateGymValue(gym)} : ${gym.NoOfReview}`);
    //     });
    // }

    return sortedList;
};

/**
 * Calculates the value for sorting gyms based on the given formula.
 * 
 * @param   {Object} gym   -   Gym object with 'thisWeekViews' and 'totalViews' fields.
 * @returns {number}       -   The calculated value for the gym.
 */
const calculateGymValue = (gym) => {
    return gym.thisWeekViews ** 2 + gym.totalViews;
};