import DinoIcon from "../../../assets/TR_locationicon.svg";
import TRLogo from "../../../assets/Icon-512.webp";
import { themeColors } from "../../../theme";

// Creates a gym icon for the marker, with styling for selected gym
export const createGymIcon = (isSelected) => {
    const el = document.createElement('div');
    el.className                = 'gym-marker';
    el.style.backgroundImage    = `url(${TRLogo})`;   // Sets gym logo as background
    el.style.backgroundSize     = 'cover';             // Ensures full coverage of the div with logo
    el.style.width              = isSelected ? '35px' : '30px'; // Larger size for selected gym
    el.style.height             = isSelected ? '35px' : '30px';
    el.style.borderRadius       = '50%';
    el.style.border             = isSelected ? `4px solid ${themeColors.complementPurple}` : 'none'; // Red border for selected gym
    return el;
};

// Creates a custom marker for user location with a dynamically colored dinosaur icon
export const createUserLocationMarker = (color = themeColors.primary) => {
    const el = document.createElement('div');
    el.className = 'user-marker';

    // Fetch the inline SVG content
    fetch(DinoIcon)
        .then((response) => response.text())
        .then((svgContent) => {
            el.innerHTML = svgContent;
            const pathElement = el.querySelector('path');
            if (pathElement) {
                pathElement.setAttribute('fill', color); // Dynamically set the fill color
            }
        });

    // el.style.width = '40px';
    // el.style.height = '40px';

    return el;
};